export type LearnerSettings = {
    confirmOrderNumberModalDesctiption: string;
    pageHeading: string;
    pageSubheadingMessage: string;
    assigningModalMessage: string;
    assigningToastSuccessMessage: string;
};

export enum LearnerHashStatusEnum {
    OK = 'OK',
    ERROR = 'ERROR',
    NOT_FOUND = 'NOT_FOUND'
}

export enum LearnerKeyEnum {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email'
}
export type Learner = {
    [LearnerKeyEnum.FIRST_NAME]: string;
    [LearnerKeyEnum.LAST_NAME]: string;
    [LearnerKeyEnum.EMAIL]: string;
};

export type LearnerSeat = {
    learner: Learner;
};

export type LearnerOrderLineItemType = {
    lineItemId: string;
    sku: string;
    type: string;
    name: string;
    qty: number;
    bundleLineItemId: string | null;
    isProvisionEnabled: boolean;
    seats: {
        available: number;
        allocated: number;
        total: number;
    };
    learnerSeats: LearnerSeat[];
};

export type LearnerOrder = {
    id: string;
    type: 'B2B' | 'B2C';
    number: string;
    lineItems: LearnerOrderLineItemType[];
    pageHash: string;
    createdAt: string; // Date string in ISO format
};

export type Booker = {
    cimContactId: string;
    email: string;
    ctCustomerId: string;
};

export type LearnerOrderInfoType = {
    order: LearnerOrder;
    booker: Booker;
    brandKey: string;
    unlockId: string;
};
