import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { validate } from '@wilm/shared-types/validation-rules';
import { FieldType, type FieldErrors, type Fields, type StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useFormat } from 'helpers/hooks/useFormat';
import Markdown from 'components/commercetools-ui/organisms/markdown';

export interface Props {
    isOpen: boolean;
    fields: Fields;
    formName: string;
    validateOnBlur?: boolean;
    modalTitle?: string;
    modalDescription?: string;
    showCloseButton?: boolean;
    closeButtonText?: string;
    showSubmitButton?: boolean;
    submitButtonText?: string;
    handleFieldChange: (field: StringFieldDefinition, value: string) => void;
    onClose: () => void;
    onSubmit: (fields: Fields) => Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }>;
}

const FieldsConfirmationModal: FC<Props> = ({
    isOpen,
    fields,
    formName,
    validateOnBlur,
    modalTitle,
    modalDescription,
    showCloseButton = false,
    closeButtonText = 'Close',
    showSubmitButton = true,
    submitButtonText = 'Submit',
    handleFieldChange,
    onClose,
    onSubmit
}) => {
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });

    const [formErrors, setFormErrors] = useState<FieldErrors>({});
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleClose = useCallback(() => {
        setFormErrors({});
        setErrorMessage('');
        onClose();
    }, [onClose]);

    const checkFields = useCallback((fields: Fields) => {
        const errors: FieldErrors = {};
        let hasErrors = false;

        for (const field of Object.values(fields)) {
            if (typeof field.value === 'string') {
                field.value = field.value.trim();
                handleFieldChange(field as StringFieldDefinition, field.value);
            }

            const error = validate(field, fields);
            if (error) {
                errors[field.name] = error;
                if (Object.keys(error).length) {
                    hasErrors = true;
                }
            }
        }

        return { errors, hasErrors };
    }, []);

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const { errors, hasErrors } = checkFields(fields);

            if (hasErrors) {
                setFormErrors(errors);
                setErrorMessage('');
                return;
            }

            const submitResponse = await onSubmit(fields);

            if (submitResponse.isError) {
                if (submitResponse.errorMessage) {
                    setErrorMessage(submitResponse.errorMessage);
                    setFormErrors({});
                    return;
                } else if (submitResponse.fieldErrors) {
                    setFormErrors(submitResponse.fieldErrors);
                    setErrorMessage('');
                }
                return;
            }

            handleClose();
        },
        [fields, onSubmit, checkFields, handleClose]
    );

    const handleFieldBlur = useCallback((field: StringFieldDefinition, fields: Fields) => {
        const error = validate(field, fields);
        if (error) {
            setFormErrors(prevState => ({ ...prevState, [field.name]: error }));
        }
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '640px' } }}
            preventScroll={true}
        >
            <form autoComplete="off" onSubmit={e => handleSubmit(e)} name={formName} noValidate>
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    {modalTitle && <h2 className="text-center text-lg font-bold">{modalTitle}</h2>}
                    {modalDescription && <Markdown markdown={modalDescription} />}
                    {Object.values(fields).map(field => {
                        // If the field is hidden, don't render it
                        if (field?.showOnPredicate?.(fields) === false) {
                            return null;
                        }

                        // Check if the field is required
                        const isRequired = field.validation.required ?? field.validation.requiredPredicate?.(fields) ?? false;

                        const errorMessage = formErrors?.[field.name]?.message;

                        const formattedErrorMessage = errorMessage
                            ? formatFieldErrorsMessage({
                                  id: errorMessage,
                                  defaultMessage: errorMessage
                              })
                            : '';

                        // Render the field based on its type
                        if (field.type === FieldType.STRING) {
                            return (
                                <div key={field.name}>
                                    <Input
                                        id={field.name}
                                        name={field.name}
                                        type="text"
                                        label={formatFieldLabelsMessage({
                                            id: `${formName}.${field.name}.label`,
                                            defaultMessage: field.name
                                        })}
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        minLength={field.validation.minLength}
                                        maxLength={field.validation.maxLength}
                                        required={isRequired}
                                        onChange={e => {
                                            handleFieldChange(field, e.target.value);
                                        }}
                                        onBlur={() => {
                                            validateOnBlur && handleFieldBlur(field, fields);
                                        }}
                                        errorMessage={formattedErrorMessage}
                                    />
                                </div>
                            );
                        }
                    })}

                    {errorMessage && <p className="text-sm text-input-error">{errorMessage}</p>}

                    {showCloseButton && closeButtonText && (
                        <Button variant="secondary" size="full" onClick={handleClose} className="rounded-md md:rounded-lg">
                            {closeButtonText}
                        </Button>
                    )}

                    {showSubmitButton && (
                        <Button variant="primary" size="full" type="submit" className="rounded-md md:rounded-lg">
                            {submitButtonText}
                        </Button>
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default FieldsConfirmationModal;
